<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div>
    <MemberTitle :memberTitle="L['采购商品报价详情']"></MemberTitle>
    <div class="containers caigouxiangqing">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #dcdfe6;
          padding-bottom: 10px;
        "
      >
        <h3 style="margin-top: 10px; font-size: 16px">
          {{ L["采购商品报价详情"] }}
        </h3>

        <div style="margin-top: 12px">
          <el-button @click="goback">返回</el-button>
        </div>
      </div>

      <div class="goodsList">
        <el-tabs
          v-model="activeName"
         class="tabs_div"
          @tab-click="handleClick"
        >
          <el-tab-pane :label="'未选择' + notQuoteCount" name="0">
            <div id="scroll-container" @scroll="handleScroll" v-if="!isLook">
              <div class="finishList" v-if="pageData.total !== 0">
                <div
                  class="finishList_item"
                  v-for="(item, index) in tableData.data"
                  :key="index"
                >
                  <!--<div
                    class="finishList_item_title"
                    v-if="Number(item.goodsName?.split('').length) <= 20"
                  >
                    <div style="margin-left: 10px;">
                      <h4 style="font-size:14px">{{ item.goodsName }}</h4>
                    </div>
                    <div style="display: flex;">
                    <p class="finishList_item_title_item" v-if="item.goodsSpec">
                      <span class="fontColor">规格型号:</span>
                      {{ item.goodsSpec }},{{ item.goodsMarque }}
                    </p>
                    <p
                      class="finishList_item_title_item"
                      v-if="!item.goodsSpec"
                    >
                      <span class="fontColor">规格型号:</span>
                      {{ item.goodsMarque }}
                    </p>
                    <p class="finishList_item_title_item">
                      <span class="fontColor">采购数量:</span
                      ><span class="bule">
                        {{ item.purchaseNum }}{{ item.marketingUnit }}
                      </span>
                    </p>
                    <p class="finishList_item_title_item">
                      <span class="fontColor">需求日期:</span>
                      {{ item.needDate }}
                    </p>
                    <p class="finishList_item_title_item flex_row_between_center">
                      <span class="fontColor">备注:</span>
                      <div class="flex_row_center_center">
                        <span class="desc-clss" :title="item.desc" style="max-width:200px; display:inline-block">{{ item.desc }}</span>
                        <span class="imgBtn" v-if="item.purchasePic" @click="previewImg(item.purchasePic)">查看图片</span>
                      </div>
                    </p>
                    </div>
                  </div>-->
                  <div
                    class="finishList_item_title2"
                  >
                    <div style="margin-left: 10px;margin-right:10px">
                      <h4 style="font-size:14px">{{ item.goodsName }}</h4>
                    </div>
                    <div style="display: flex; margin-top: 5px">
                      <p
                        class="finishList_item_title_item"
                        v-if="item.goodsSpec"
                      >
                        <span class="fontColor">规格型号:</span>
                        {{ item.goodsSpec }},{{ item.goodsMarque }}
                      </p>
                      <p
                        class="finishList_item_title_item"
                        v-if="!item.goodsSpec"
                      >
                        <span class="fontColor">规格型号:</span>
                        {{ item.goodsMarque }}
                      </p>
                      <p class="finishList_item_title_item">
                        <span class="fontColor">采购数量:</span
                        ><span class="bule">
                          {{ item.purchaseNum }}{{ item.marketingUnit }}
                        </span>
                      </p>
                      <p class="finishList_item_title_item">
                        <span class="fontColor">需求日期:</span>
                        {{ item.needDate }}
                      </p>
                      <p class="finishList_item_title_item flex_row_between_center">
                        <span class="fontColor">备注:</span>
                        <div class="flex_row_center_center">
                          <span class="desc-clss" :title="item.desc" style="max-width:200px; display:inline-block">{{ item.desc }}</span>
                          <span class="imgBtn" v-if="item.purchasePic" @click="previewImg(item.purchasePic)">查看图片</span>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div class="OtherRecommendations">
                    <el-carousel
                      trigger="click"
                      height="365px"
                      :autoplay="false"
                      arrow="always"
                       initial-index="0"
                      :motion-blur="true"
                      :interval="5000"
                      indicator-position="none"
                      ref="carousel"


                      @change="
                        changeGoosList(
                          item.goodsList.pageNum,
                          item.memberNeedCode,
                          item
                        )
                      "
                    >

                      <el-carousel-item
                        v-for="Items in item.goodsList.totalPage"
                        :key="Items"
                        style="background: #fafafaff"
                      ><h3 class="small justify-center" text="2xl">
                        <div class="goodsDiv" id="checkedTab">
                          <div
                            v-for="(goodsItem, index) in item.goodsList.data"
                            :key="index"
                            class="goodsDiv_item"
                            :style="
                              goodsItem.goodsName == '我的报价'
                                ? 'height:300px'
                                : ''
                            "
                          >
                            <img
                              :src="goodsItem.marqueImage"
                              alt=""
                              v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode"
                              @click="
                                goGoodsDetail(
                                  goodsItem.goodsName,
                                  goodsItem.skuCode
                                )
                              "
                            />
                            <img
                              :src="emptyImg"
                              alt=""
                              v-if="!goodsItem.skuCode"
                              @click="
                                goGoodsDetail(
                                  goodsItem.goodsName,
                                  goodsItem.skuCode
                                )
                              "
                            />
                            <p class="checked" v-if="goodsItem.isChoose">
                              已选中
                            </p>
                            <div style="padding: 0 5px 10px 5px; background-color: #FFFFFFFF;">
                              <el-tooltip
                                v-if="goodsItem.goodsName?.split('').length > 25"
                                class="box-item"
                                effect="dark"
                                :content="goodsItem.goodsName"
                                placement="top-start"
                              >
                                <p
                                  class="goodName"
                                  @click="
                                    goGoodsDetail(
                                      goodsItem.goodsName,
                                      goodsItem.skuCode
                                    )
                                  "
                                >
                                  {{ goodsItem.goodsName }}
                                </p>
                              </el-tooltip>
                              <p
                                class="goodName"
                                v-if="goodsItem.goodsName?.split('').length <= 25"
                                @click="
                                  goGoodsDetail(
                                    goodsItem.goodsName,
                                    goodsItem.skuCode
                                  )
                                "
                              >
                                {{ goodsItem.goodsName }}
                              </p>
                              <el-tooltip
                                v-if="
                                  goodsItem.supplierUserName?.split('').length >
                                  20
                                "
                                class="box-item"
                                effect="dark"
                                :content="goodsItem.supplierUserName"
                                placement="top-start"
                              >
                                <p class="supplierName">
                                  <span
                                    class="mySupplierName"
                                    v-if="goodsItem.isMySupplier !== 0"
                                    >供</span
                                  ><span>{{ goodsItem.supplierUserName }}</span>
                                </p>
                              </el-tooltip>
                              <p
                                class="supplierName"
                                v-if="
                                  goodsItem.supplierUserName?.split('').length <=
                                  20
                                "
                              >
                                <span
                                  class="mySupplierName"
                                  v-if="goodsItem.isMySupplier !== 0"
                                  >供</span
                                ><span>{{ goodsItem.supplierUserName }}</span>
                              </p>
                              <p v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode">
                                <span>成本价</span> :
                                <span>￥{{ goodsItem.quotePrice }}</span>
                              </p>
                              <p v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode">
                                <span>售价</span> :
                                <span class="redColor"
                                  >￥{{ goodsItem.sellingPrice }}</span
                                >
                              </p>
                              <div  style="display:flex;align-Items:center;">
                              <p v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode">
                                <span>利润</span> :
                                <span>￥{{ goodsItem.profit }}</span>
                              </p>
                              <p v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode"  style="margin-left:3px;">
                                <span
                                  class="expirationDate"
                                  v-if="goodsItem.quoteTime !== '长期有效'"
                                >
                                  {{ goodsItem.quoteTime }}前有效
                                </span>
                                <span
                                  class="expirationDate"
                                  v-if="goodsItem.quoteTime == '长期有效'"
                                >
                                 {{ goodsItem.quoteTime }}
                                </span>
                              </p>
                              </div>
                              <p v-if="goodsItem.goodsName == '我的报价'&&(!goodsItem.skuCode)">
                                <span>报价</span>:
                                <span class="redColor"
                                  >￥{{ goodsItem.quotePrice }}</span
                                >
                              </p>
                              <p v-if="goodsItem.goodsName !== '我的报价'&&(!goodsItem.skuCode)">
                                <span>报价</span>:
                                <span class="redColor"
                                  >￥{{ goodsItem.quotePrice }}</span
                                >
                              </p>
                              <span class="QuotationTime" v-if="goodsItem.pushTime">
                                  {{goodsItem.pushTime}} 报价
                              </span>
                              <p v-if="goodsItem.isChoose == 1">
                                <span>选择原因</span>:
                                <span
                                  class="viewRemark"
                                  @click="viewRemark(goodsItem)"
                                  >查看</span
                                >
                              </p>
                              <div
                              class="selectGoods"
                              @click="
                                changeGoods(
                                  goodsItem.goodsId,
                                  goodsItem.memberNeedCode,
                                  item,goodsItem
                                )
                              "
                              v-if="goodsItem.isChoose == 0"
                            >
                              选择
                            </div>
                            <div
                              class="selectGoods2"
                              v-if="goodsItem.isChoose == 1"
                            >
                              已选择
                            </div>
                            </div>

                          </div>
                        </div>
                         </h3>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
            </div>
            <div id="scroll-container" @scroll="handleScroll2" v-if="isLook">
                  <div class="finishList" v-if="SupplierViewData.goodsList.length !== 0" >
                    <div
                      class="finishList_item"
                      v-for="(item, index) in SupplierViewData.goodsList"
                      :key="index"
                    >
                      <div class="finishList_item_title2">
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width:100%;
                          "
                        >
                          <div>
                            <div style="display: flex; align-items: center">
                              <p
                                style="font-size: 14px; font-weight: bold; margin-left: 10px"
                              >
                                {{ item.supplierUserName }}
                              </p>
                            </div>
                            <div style="display: flex;margin-left:10px">
                              <p class="finishList_item_title_item">
                                <span class="fontColor">计划采购商品数量:</span
                                ><span class="bule">
                                  {{ item.allGoodsCount}}
                                </span>
                              </p>
                              <p class="finishList_item_title_item">
                                <span class="fontColor">已报价采购商品数量:</span
                                ><span class="bule">
                                  {{ item.num.length }}
                                </span>
                              </p>

                              <p class="finishList_item_title_item" style="color: red">
                                <span class="fontColor" style="color: red"
                                  >已报价总金额:</span
                                >
                                {{ item.quoteAlMoney }}
                              </p>

                              <!--<p
                                class="finishList_item_title_item"
                                v-if="item.isChooseMySupplier == 1"
                              >
                                <span class="fontColor">我的报价:</span
                                ><span class="yellowColor"
                                  >{{ item.mySupplierName }} ￥{{ item.myPrice }}</span
                                >
                              </p>-->
                            </div>
                          </div>

                          <div
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: space-between;
                            "
                          >
                            <p class="select" v-if="item.isChooseMySupplier == 1">已选中</p>
                            <p class="tag" v-if="item.isPlaceAnOrder">已下单</p>
                            <p  class="checkedAll" @click="selectAll(item)">选择全部</p>
                            <!--<p v-if="item.isCancel" class="checkedAll" @click="CancelAll(item)">取消全部</p>-->
                            <!--<el-button size="mini" @click="handleCloseItem(item.needCode)">关闭</el-button>-->
                          </div>
                        </div>
                      </div>

                      <div class="OtherRecommendations">
                        <el-carousel
                          ref="cardShow"
                          trigger="click"
                          height="370px"
                          :autoplay="false"
                          arrow="always"
                          initial-index="0"
                          :motion-blur="true"
                          :interval="5000"
                          indicator-position="none"

                          v-if="item.goodsList.data.length !== 0"
                        >
                         <!--@change="
                            handleCarouselChange(item.goodsList.pageNum, item.needCode, item)
                          "-->
                          <el-carousel-item
                            v-for="Items,indes in item.goodsList.data"
                            :key="indes"
                            style="background: #fafafaff"
                          >
                            <!--<h3 class="small justify-center" text="2xl">-->
                            <!--<i  @click="handleCarouselChange('prev',item.goodsList.pageNum, item.needCode, item)"  class="iconfont icon-ziyuan2 arrow-left" ></i>
                            <i class="iconfont icon-ziyuan11 arrow-right"  @click="handleCarouselChange('next',item.goodsList.pageNum, item.needCode, item)"></i>-->
                            <div class="goodsDiv" style="margin: 10px 58px 20px 58px;">
                              <div
                                class="goodsDiv_item"
                               v-for="(goodsItem, index) in Items"
                                :key="index"
                                 style="width:200px;margin-right: 10px;"
                              >
                                <img
                                  :src="goodsItem.goodsImage"
                                  alt=""
                                  @click="
                                    goGoodsDetail(goodsItem.sellingPrice, goodsItem.skuCode)
                                  "
                                  style="width:200px;"
                                />
                                <p class="checked" v-if="goodsItem.isChooseGoods">已选中</p>
                                <div
                                  style="
                                    padding: 0 5px 10px 5px;
                                    background-color: #ffffffff;
                                    line-height: 1.5;
                                    color:color: #999999FF;
                                  "
                                >
                                  <p
                                    class="goodName"
                                    @click="
                                      goGoodsDetail(goodsItem.sellingPrice, goodsItem.skuCode)
                                    "
                                  >
                                    {{ goodsItem.goodsNameJoin }}
                                  </p>

                                  <p>
                                    <span>规格型号：</span>
                                    <span v-if="goodsItem.goodsSpec && goodsItem.marque"
                                      >{{ goodsItem.goodsSpec }},{{ goodsItem.marque }}</span
                                    >
                                    <span v-if="!goodsItem.goodsSpec">{{
                                      goodsItem.marque
                                    }}</span>
                                  </p>
                                  <p>
                                    <span>采购数量：</span>
                                    <span
                                      >{{ goodsItem.purchaseNum }}{{
                                        goodsItem.marketingUnit
                                      }}</span
                                    >
                                  </p>

                                  <p>
                                    <span>需求日期:</span>
                                    {{ goodsItem.needDate }}
                                  </p>
                                  <!--<p v-if="store.state.memberInfo.isNeedMyPrice == 0">
                                    <span>我的供应商:</span>
                                    {{ goodsItem.mySupplierName }}
                                  </p>
                                  <p>
                                    <span>我的报价:</span>
                                    {{ goodsItem.myPrice }}元
                                  </p>-->
                                  <div
                                    style="
                                      margin-top: 3px;
                                      display: flex;
                                      align-items: center;
                                    "
                                  ><p>
                                    <span>平台售价:</span>
                                    <span class="redColor"
                                      >￥{{ goodsItem.sellingPrice }}</span
                                    >
                                    </p><span
                                      class="expirationDate"
                                      v-if="
                                        goodsItem.quoteTime !== '长期有效' &&
                                        goodsItem.sellingPrice?.split('').length <= 5
                                      "
                                      style="margin-left:5px"
                                    >
                                      {{ goodsItem.quoteTime }}前有效
                                    </span>
                                    <span
                                      class="expirationDate"
                                      v-if="
                                        goodsItem.quoteTime == '长期有效' &&
                                        goodsItem.sellingPrice?.split('').length <= 5
                                      "
                                    >
                                      {{ goodsItem.quoteTime }}
                                    </span>
                                    <p
                                      class="expirationDate2"
                                      v-if="
                                        goodsItem.quoteTime == '长期有效' &&
                                        goodsItem.sellingPrice?.split('').length5
                                      "
                                    >
                                      {{ goodsItem.quoteTime }}
                                    </p>
                                  </div>
                                  <p style="font-size: 10px">
                                    <span>平台报价时间：</span>
                                    <span>{{ goodsItem.pushTime }}</span>
                                  </p>
                                  <p v-if="goodsItem.isChoose == 1">
                                  <span>选择原因</span>:
                                    <span  class="viewRemark"  @click="viewRemark(goodsItem)"
                                      >查看</span
                                    >
                                  </p>
                                  <div
                                    v-if="(goodsItem?.isChooseNeed==0)&&(!goodsItem.isChooseGoods)"
                                    class="selectGoods"
                                     style="width:195px;"
                                    @click="
                                      changeGoods2(
                                        goodsItem.goodsId,
                                        goodsItem.memberNeedCode,
                                      item,goodsItem


                                      )
                                    "
                                  >
                                    选择
                                  </div>
                                  <div
                                    v-if="goodsItem?.isChooseNeed==1"
                                    class="GreyOutButton"
                                     style="width:195px;"
                                  >
                                    选择
                                  </div>

                                  <div
                                    v-if="goodsItem?.isChooseNeed==0&&goodsItem.isChooseGoods"
                                    class="selectGoods"
                                     style="width:195px;"
                                    @click="
                                      cancelGoods(

                                         goodsItem.goodsId,
                                        goodsItem.memberNeedCode,
                                      item,
                                      )
                                    "
                                  >
                                    取消
                                  </div>
                                </div>
                                <div

                                ></div>
                              </div>
                            </div>
                            <!--</h3>-->
                          </el-carousel-item>
                        </el-carousel>

                        <el-empty
                          description="暂无其他推荐"
                          v-if="item.goodsList.data.length == 0"
                        />
                      </div>
                    </div>
                  </div>
            </div>
            <el-empty description="暂无数据" v-if="pageData.total == 0&&(!isLook)" />
             <el-empty description="暂无数据" v-if="SupplierPageData.total == 0&&(isLook)" />
            <el-divider
              border-style="dotted"
            />
            <div class="generateOrder">
              <div class="generateOrder_left">
                <span>
                  <span
                    ><span class="fontColor">采购计划总数:</span>
                    <span>
                       {{ notQuoteCount + alreadyQuoteCount + closeQuoteCount }} </span
                    >件</span
                  >
                </span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="'已选择' + alreadyQuoteCount" name="1">
          <div id="scroll-container" @scroll="handleScroll" v-if="!isLook">
            <div class="finishList" v-if="alreadyQuoteCount !== 0">
              <div
                class="finishList_item"
                v-for="(item, index) in tableData.goodsList"
                :key="index"
              >
                <!--<div
                  class="finishList_item_title"
                  v-if="Number(item.goodsName?.split('').length) <= 20"
                >
                  <div style="font-size:14px">
                    <h4>{{ item.goodsName }}</h4>
                  </div>
                  <p class="finishList_item_title_item" v-if="item.goodsSpec">
                    <span class="fontColor">规格型号:</span>
                    {{ item.goodsSpec }},{{ item.goodsMarque }}
                  </p>
                  <p class="finishList_item_title_item" v-if="!item.goodsSpec">
                    <span class="fontColor">规格型号:</span>
                    {{ item.goodsMarque }}
                  </p>
                  <p class="finishList_item_title_item">
                    <span class="fontColor">采购数量:</span
                    ><span class="bule">
                      {{ item.purchaseNum }}{{ item.marketingUnit }}
                    </span>
                  </p>
                  <p class="finishList_item_title_item">
                    <span class="fontColor">需求日期:</span>
                    {{ item.needDate }}
                  </p>
                  <p class="finishList_item_title_item flex_row_between_center">
                    <span class="fontColor">备注:</span>
                    <div class="flex_row_center_center">
                      <span class="desc-clss" :title="item.desc" style="max-width:200px; display:inline-block">{{ item.desc }}</span>
                      <span class="imgBtn" v-if="item.purchasePic" @click="previewImg(item.purchasePic)">查看图片</span>
                    </div>
                  </p>
                </div>-->
                <div
                  class="finishList_item_title2"

                >
                  <div style="margin-left: 10px;margin-right: 10px;font-size:14px;">
                    <h4>{{ item.goodsName }}</h4>
                  </div>
                  <div style="display: flex; margin-top: 5px;margin-left:10px">
                    <p class="finishList_item_title_item" v-if="item.goodsSpec">
                      <span class="fontColor">规格型号:</span>
                      {{ item.goodsSpec }},{{ item.goodsMarque }}
                    </p>
                    <p
                      class="finishList_item_title_item"
                      v-if="!item.goodsSpec"
                    >
                      <span class="fontColor">规格型号:</span>
                      {{ item.goodsMarque }}
                    </p>
                    <p class="finishList_item_title_item">
                      <span class="fontColor">采购数量:</span
                      ><span class="bule">
                        {{ item.purchaseNum }}{{ item.marketingUnit }}
                      </span>
                    </p>
                    <p class="finishList_item_title_item">
                      <span class="fontColor">需求日期:</span>
                      {{ item.needDate }}
                    </p>
                    <p class="finishList_item_title_item flex_row_between_center">
                      <span class="fontColor">备注:</span>
                      <div class="flex_row_center_center">
                        <span class="desc-clss" :title="item.desc" style="max-width:200px; display:inline-block">{{ item.desc }}</span>
                        <span class="imgBtn" v-if="item.purchasePic" @click="previewImg(item.purchasePic)">查看图片</span>
                      </div>
                    </p>
                  </div>
                </div>
                <div class="OtherRecommendations">
                     <!-- height="395px" -->
                  <el-carousel
                      trigger="click"

                      :autoplay="false"
                      arrow="always"
                       initial-index="0"
                      :motion-blur="true"
                      :interval="5000"
                      indicator-position="none"
                      ref="carousel"


                      @change="
                        changeGoosList(
                          item.goodsList.pageNum,
                          item.memberNeedCode,
                          item
                        )
                      "
                    >
                    <el-carousel-item
                      v-for="Items in item.goodsList.totalPage+1"
                        :key="Items"
                        style="background: #fafafaff"

                    >
                    <h3 class="small justify-center" text="2xl">
                      <div class="goodsDiv">
                        <div
                          v-for="(goodsItem, index) in item.goodsList.data"
                          :key="index"
                          class="goodsDiv_item"
                          :style="
                            goodsItem.goodsName == '我的报价'
                              ? 'height:300px'
                              : ''
                          "
                        >
                          <img
                            :src="goodsItem.marqueImage"
                            alt=""
                            v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode"
                            @click="
                              goGoodsDetail(
                                goodsItem.goodsName,
                                goodsItem.skuCode
                              )
                            "
                          />
                          <img
                            :src="emptyImg"
                            alt=""
                            v-if="(!goodsItem.skuCode)"
                            @click="
                              goGoodsDetail(
                                goodsItem.goodsName,
                                goodsItem.skuCode
                              )
                            "
                          />
                          <p class="checked" v-if="goodsItem.isChoose">
                            已选中
                          </p>
                          <div style="padding: 0 5px 10px 5px; background-color: #FFFFFFFF;">
                            <el-tooltip
                              v-if="goodsItem.goodsName?.split('').length > 25"
                              class="box-item"
                              effect="dark"
                              :content="goodsItem.goodsName"
                              placement="top-start"
                            >
                              <p
                                class="goodName"
                                @click="
                                  goGoodsDetail(
                                    goodsItem.goodsName,
                                    goodsItem.skuCode
                                  )
                                "
                              >
                                {{ goodsItem.goodsName }}
                              </p>
                            </el-tooltip>
                            <p
                              class="goodName"
                              v-if="goodsItem.goodsName?.split('').length <= 25"
                              @click="
                                goGoodsDetail(
                                  goodsItem.goodsName,
                                  goodsItem.skuCode
                                )
                              "
                            >
                              {{ goodsItem.goodsName }}
                            </p>
                            <el-tooltip
                              v-if="
                                goodsItem.supplierUserName?.split('').length > 20
                              "
                              class="box-item"
                              effect="dark"
                              :content="goodsItem.supplierUserName"
                              placement="top-start"
                            >
                              <p class="supplierName">
                                <span
                                  class="mySupplierName"
                                  v-if="goodsItem.isMySupplier !== 0"
                                  >供</span
                                ><span>{{ goodsItem.supplierUserName }}</span>
                              </p>
                            </el-tooltip>
                            <p
                              class="supplierName"
                              v-if="
                                goodsItem.supplierUserName?.split('').length <=
                                20
                              "
                            >
                              <span
                                class="mySupplierName"
                                v-if="goodsItem.isMySupplier !== 0"
                                >供</span
                              ><span>{{ goodsItem.supplierUserName }}</span>
                            </p>
                            <p v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode">
                              <span>成本价</span> :
                              <span>￥{{ goodsItem.quotePrice }}</span>
                            </p>
                            <p v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode">
                              <span>售价</span> :
                              <span class="redColor"
                                >￥{{ goodsItem.sellingPrice }}</span
                              >
                            </p>
                            <div style="display:flex;align-Items:center;">
                              <p v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode">
                                <span>利润</span> :
                                <span>￥{{ goodsItem.profit }}</span>
                              </p>
                              <p v-if="goodsItem.goodsName !== '我的报价'&&(goodsItem.skuCode)"  style="margin-left:3px;">
                                <span
                                  class="expirationDate"
                                  v-if="goodsItem.quoteTime !== '长期有效'"
                                >
                                  {{ goodsItem.quoteTime }}前有效
                                </span>
                                <span
                                  class="expirationDate"
                                  v-if="goodsItem.quoteTime == '长期有效'"
                                >
                                 {{ goodsItem.quoteTime }}
                                </span>
                              </p>
                              </div>
                            <p v-if="goodsItem.goodsName == '我的报价'&&(!goodsItem.skuCode)">
                              <span>报价</span>:
                              <span class="redColor"
                                >￥{{ goodsItem.quotePrice }}</span
                              >
                            </p>
                            <p v-if="goodsItem.goodsName !== '我的报价'&&(!goodsItem.skuCode)">
                              <span>报价</span>:
                              <span class="redColor"
                                >￥{{ goodsItem.quotePrice }}</span
                              >
                            </p>
                            <span class="QuotationTime"  v-if="goodsItem.pushTime">

                                {{goodsItem.pushTime}} 报价
                            </span>
                            <p v-if="goodsItem.isChoose == 1">
                                <span>选择原因</span>:
                                <span  class="viewRemark"  @click="viewRemark(goodsItem)"
                                  >查看</span
                                >
                              </p>
                            <div
                            class="selectGoods"
                            @click="
                              changeGoods(
                                goodsItem.goodsId,
                                goodsItem.memberNeedCode,
                                item,goodsItem
                              )
                            "
                            v-if="goodsItem.isChoose == 0"
                          >
                            选择
                          </div>
                          <div
                            class="selectGoods2"
                            v-if="goodsItem.isChoose == 1"
                          >
                            已选择
                          </div>
                          </div>

                        </div>
                      </div>
                    </h3>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
          </div>
          <div id="scroll-container" @scroll="handleScroll2" v-if="isLook">
                  <div class="finishList" v-if="SupplierViewData.goodsList.length !== 0" >
                    <div
                      class="finishList_item"
                      v-for="(item, index) in SupplierViewData.goodsList"
                      :key="index"
                    >
                      <div class="finishList_item_title2">
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width:100%;
                          "
                        >
                          <div>
                            <div style="display: flex; align-items: center">
                              <p
                                style="font-size: 14px; font-weight: bold; margin-left: 10px"
                              >
                                {{ item.supplierUserName }}
                              </p>
                            </div>
                            <div style="display: flex;margin-left:10px">
                              <p class="finishList_item_title_item">
                                <span class="fontColor">计划采购商品数量:</span
                                ><span class="bule">
                                  {{ item.allGoodsCount }}
                                </span>
                              </p>
                              <p class="finishList_item_title_item">
                                <span class="fontColor">已报价采购商品数量:</span
                                ><span class="bule">
                                  {{ item.num.length }}
                                </span>
                              </p>

                              <p class="finishList_item_title_item" style="color: red">
                                <span class="fontColor" style="color: red"
                                  >已报价总金额:</span
                                >
                                {{ item.quoteAlMoney }}
                              </p>

                              <!--<p
                                class="finishList_item_title_item"
                                v-if="item.isChooseMySupplier == 1"
                              >
                                <span class="fontColor">我的报价:</span
                                ><span class="yellowColor"
                                  >{{ item.mySupplierName }} ￥{{ item.myPrice }}</span
                                >
                              </p>-->
                            </div>
                          </div>

                          <div
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: space-between;

                            "
                          >
                            <p class="select" v-if="item.isChooseMySupplier == 1">已选中</p>
                            <p class="tag" v-if="item.isPlaceAnOrder">已下单</p>
                            <p v-if="!item.isCancel" class="checkedAll" @click="selectAll(item)">选择全部</p>
                            <p v-if="item.isCancel" class="checkedAll" @click="CancelAll(item)">取消全部</p>
                            <!--<el-button size="mini" @click="handleCloseItem(item.needCode)">关闭</el-button>-->
                          </div>
                        </div>
                      </div>

                      <div class="OtherRecommendations">
                        <el-carousel
                          trigger="click"
                          :height="cardShowChoose"
                          :autoplay="false"
                          arrow="always"
                          initial-index="0"
                          :motion-blur="true"
                          :interval="5000"
                          indicator-position="none"

                          v-if="item.goodsList.data.length !== 0"
                        >
                        <!--@change="
                            handleCarouselChange(item.goodsList.pageNum, item.needCode, item)
                          "-->
                          <el-carousel-item
                             v-for="Items,indes in item.goodsList.data"
                            :key="indes"
                            style="background: #fafafaff"
                          >
                            <!--<h3 class="small justify-center" text="2xl">-->
                            <!--<i  @click="handleCarouselChange('prev',item.goodsList.pageNum, item.needCode, item)"  class="iconfont icon-ziyuan2 arrow-left" ></i>
                            <i class="iconfont icon-ziyuan11 arrow-right"  @click="handleCarouselChange('next',item.goodsList.pageNum, item.needCode, item)"></i>-->
                            <div class="goodsDiv"  style="margin: 10px 58px 20px 58px;" id="chooseitem">
                              <div
                                class="goodsDiv_item"
                                v-for="(goodsItem, index) in Items"
                                :key="index"
                                style="width:200px;margin-right:10px"
                              >
                                <img
                                  :src="goodsItem.goodsImage"
                                  alt=""
                                  @click="
                                    goGoodsDetail(goodsItem.sellingPrice, goodsItem.skuCode)
                                  "
                                  style="width:200px"
                                />
                                <p class="checked" v-if="goodsItem.isChooseGoods">已选中</p>
                                <div
                                  style="
                                    padding: 0 5px 10px 5px;
                                    background-color: #ffffffff;
                                    line-height: 1.5;
                                    color:color: #999999FF;
                                  "
                                >
                                  <p
                                    class="goodName"
                                    @click="
                                      goGoodsDetail(goodsItem.sellingPrice, goodsItem.skuCode)
                                    "
                                  >
                                    {{ goodsItem.goodsNameJoin }}
                                  </p>

                                  <p>
                                    <span>规格型号：</span>
                                    <span v-if="goodsItem.goodsSpec && goodsItem.marque"
                                      >{{ goodsItem.goodsSpec }},{{ goodsItem.marque }}</span
                                    >
                                    <span v-if="!goodsItem.goodsSpec">{{
                                      goodsItem.marque
                                    }}</span>
                                  </p>
                                  <p>
                                    <span>采购数量：</span>
                                    <span
                                      >{{ goodsItem.purchaseNum }}{{
                                        goodsItem.marketingUnit
                                      }}</span
                                    >
                                  </p>

                                  <p>
                                    <span>需求日期:</span>
                                    {{ goodsItem.needDate }}
                                  </p>
                                  <!--<p>
                                    <span>我的供应商:</span>
                                    {{ goodsItem.mySupplierName }}
                                  </p>
                                  <p>
                                    <span>我的报价:</span>
                                    {{ goodsItem.myPrice }}元
                                  </p>-->
                                  <div
                                    style="
                                      margin-top: 3px;
                                      display: flex;
                                      align-items:
                                      center;

                                      width:195px;

                                    "
                                  ><p>
                                    <span>平台售价:</span>
                                    <span class="redColor"
                                      >￥{{ goodsItem.sellingPrice }}</span
                                    >
                                    </p><span
                                      class="expirationDate"
                                      style="margin-left:5px"
                                      v-if="
                                        goodsItem.quoteTime !== '长期有效' &&
                                        goodsItem.sellingPrice.split('').length <= 5
                                      "
                                    >
                                      {{ goodsItem.quoteTime }}前有效
                                    </span>
                                    <span
                                      class="expirationDate"
                                      v-if="
                                        goodsItem.quoteTime == '长期有效' &&
                                        goodsItem.sellingPrice.split('').length <= 5
                                      "
                                    >
                                      {{ goodsItem.quoteTime }}
                                    </span>
                                    <p
                                      class="expirationDate2"
                                      v-if="
                                        goodsItem.quoteTime == '长期有效' &&
                                        goodsItem.sellingPrice.split('').length>5
                                      "
                                    >
                                      {{ goodsItem.quoteTime }}
                                    </p>
                                  </div>
                                  <p style="font-size: 10px">
                                    <span>平台报价时间：</span>
                                    <span>{{ goodsItem.pushTime }}</span>
                                  </p>
                                  <p v-if="goodsItem.isChooseGoods">
                                <span>选择原因</span>:
                                <span  class="viewRemark"  @click="viewRemark(goodsItem)"

                                > 查看</span>
                              </p>
                                  <div
                                    v-if="!goodsItem.isChooseGoods"
                                    class="selectGoods"
                                     style="width:195px;"
                                    @click="
                                      changeGoods2(
                                        goodsItem.goodsId,
                                        goodsItem.memberNeedCode,
                                      item,goodsItem

                                      )
                                    "
                                  >
                                    选择
                                  </div>
                                  <div
                                    v-if="goodsItem.isChooseGoods"
                                    class="selectGoods"
                                     style="width:195px;"
                                    @click="
                                      cancelGoods(
                                         goodsItem.goodsId,
                                        goodsItem.memberNeedCode,
                                      item,
                                      )
                                    "
                                  >
                                    取消
                                  </div>
                                </div>
                                <div
                                  :style="
                                    goodsItem?.select
                                      ? 'position: absolute; top:0;bottom:0;left:0;right:0;background:rgba(0,0,0,0.25)'
                                      : ''
                                  "
                                ></div>
                              </div>
                            </div>
                            <!--</h3>-->
                          </el-carousel-item>
                        </el-carousel>

                        <el-empty
                          description="暂无其他推荐"
                          v-if="item.goodsList.data.length == 0"
                        />
                      </div>
                    </div>
                  </div>
            </div>
            <el-empty
              description="暂无数据"
              v-if="alreadyQuoteCount == 0&&(!isLook)"
            />
            <el-empty description="暂无数据" v-if="SupplierPageData.total == 0&&(isLook)" />
            <el-divider border-style="dotted" />
            <div class="generateOrder">
              <div class="generateOrder_left">
                <span>
                  <span
                    ><span class="fontColor">采购计划总数:</span>
                    <span>{{ notQuoteCount + alreadyQuoteCount + closeQuoteCount }}</span
                    >件</span
                  >
                </span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="'已关闭' + closeQuoteCount" name="3">
          <div id="scroll-container" @scroll="handleScroll">
            <div class="finishList" v-if="closeQuoteCount !== 0">
              <div
                class="finishList_item"
                v-for="(item, index) in closeData.goodsList"
                :key="index"
              >
                <div
                  class="finishList_item_title"
                  v-if="Number(item.goodsName.split('').length) < 20"
                  :style="item.goodsList.data.length==0?'border-bottom:none':''"
                >
                  <div style="margin-left: 10px">
                    <h4>{{ item.goodsName }}</h4>
                  </div>
                  <p class="finishList_item_title_item" v-if="item.goodsSpec">
                    <span class="fontColor">规格型号:</span>
                    {{ item.goodsSpec }},{{ item.goodsMarque }}
                  </p>
                  <p class="finishList_item_title_item" v-if="!item.goodsSpec">
                    <span class="fontColor">规格型号:</span>
                    {{ item.goodsMarque }}
                  </p>
                  <p class="finishList_item_title_item">
                    <span class="fontColor">采购数量:</span
                    ><span class="bule">
                      {{ item.purchaseNum }}{{ item.marketingUnit }}
                    </span>
                  </p>
                  <p class="finishList_item_title_item">
                    <span class="fontColor">需求日期:</span>
                    {{ item.needDate }}
                  </p>
                  <p class="finishList_item_title_item flex_row_between_center">
                    <span class="fontColor">备注:</span>
                    <div class="flex_row_center_center">
                      <span class="desc-clss" :title="item.desc" style="max-width:200px; display:inline-block">{{ item.desc }}</span>
                      <span class="imgBtn" v-if="item.purchasePic" @click="previewImg(item.purchasePic)">查看图片</span>
                    </div>
                  </p>
                </div>
                <div
                  class="finishList_item_title2"
                  v-if="Number(item.goodsName.split('').length) > 20"
                >
                  <div style="margin-left: 10px">
                    <h4>{{ item.goodsName }}</h4>
                  </div>
                  <div style="display: flex; margin-top: 5px">
                    <p class="finishList_item_title_item" v-if="item.goodsSpec">
                      <span class="fontColor">规格型号:</span>
                      {{ item.goodsSpec }},{{ item.goodsMarque }}
                    </p>
                    <p
                      class="finishList_item_title_item"
                      v-if="!item.goodsSpec"
                    >
                      <span class="fontColor">规格型号:</span>
                      {{ item.goodsMarque }}
                    </p>
                    <p class="finishList_item_title_item">
                      <span class="fontColor">采购数量:</span
                      ><span class="bule">
                        {{ item.purchaseNum }}{{ item.marketingUnit }}
                      </span>
                    </p>
                    <p class="finishList_item_title_item">
                      <span class="fontColor">需求日期:</span>
                      {{ item.needDate }}
                    </p>
                    <p class="finishList_item_title_item flex_row_between_center">
                      <span class="fontColor">备注:</span>
                      <div class="flex_row_center_center">
                        <span class="desc-clss" :title="item.desc" style="max-width:200px; display:inline-block">{{ item.desc }}</span>
                        <span class="imgBtn" v-if="item.purchasePic" @click="previewImg(item.purchasePic)">查看图片</span>
                      </div>
                    </p>
                  </div>
                </div>
                <div class="OtherRecommendations">
                  <el-carousel
                    trigger="click"
                   height="340px"
                    :autoplay="false"
                    arrow="always"
                    indicator-position="none"

                    v-if="item.goodsList.data.length !==0"
                    :interval="5000"
                    initial-index="0"
                    ref="carousel"
                    :motion-blur="true"
                    @change="
                      changeGoosList(
                        item.goodsList.pageNum,
                        item.memberNeedCode,
                        item
                      )
                    "
                  >
                    <el-carousel-item
                      v-for="(Items, indexs) in Number(
                        item.goodsList.totalPage
                      )"
                      :key="indexs"
                      name="index"

                    >
                      <div class="goodsDiv">
                        <div
                          v-for="(goodsItem, index) in item.goodsList.data"
                          :key="index"
                          class="goodsDiv_item"
                          :style="
                            goodsItem.goodsName == '我的报价'
                              ? 'height:300px'
                              : ''
                          "
                        >
                          <img
                            :src="goodsItem.marqueImage"
                            alt=""
                            v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode"
                            @click="
                              goGoodsDetail(
                                goodsItem.goodsName,
                                goodsItem.skuCode
                              )
                            "
                          />
                          <img
                            src="@/assets/empty.png"
                            alt=""
                            v-if="(!goodsItem.skuCode)"
                            @click="
                              goGoodsDetail(
                                goodsItem.goodsName,
                                goodsItem.skuCode
                              )
                            "
                          />
                          <p class="checked" v-if="goodsItem.isChoose">
                            已选中
                          </p>
                          <div style="padding: 0 5px 10px 5px; background-color: #FFFFFFFF;">
                            <el-tooltip
                              v-if="goodsItem.goodsName.split('').length > 25"
                              class="box-item"
                              effect="dark"
                              :content="goodsItem.goodsName"
                              placement="top-start"
                            >
                              <p
                                class="goodName"
                                @click="
                                  goGoodsDetail(
                                    goodsItem.goodsName,
                                    goodsItem.skuCode
                                  )
                                "
                              >
                                {{ goodsItem.goodsName }}
                              </p>
                            </el-tooltip>
                            <p
                              class="goodName"
                              v-if="goodsItem.goodsName.split('').length <= 25"
                              @click="
                                goGoodsDetail(
                                  goodsItem.goodsName,
                                  goodsItem.skuCode
                                )
                              "
                            >
                              {{ goodsItem.goodsName }}
                            </p>
                            <el-tooltip
                              v-if="
                                goodsItem.supplierUserName.split('').length > 20
                              "
                              class="box-item"
                              effect="dark"
                              :content="goodsItem.supplierUserName"
                              placement="top-start"
                            >
                              <p class="supplierName">
                                <span
                                  class="mySupplierName"
                                  v-if="goodsItem.isMySupplier !== 0"
                                  >供</span
                                ><span>{{ goodsItem.supplierUserName }}</span>
                              </p>
                            </el-tooltip>
                            <p
                              class="supplierName"
                              v-if="
                                goodsItem.supplierUserName.split('').length <=
                                20
                              "
                            >
                              <span
                                class="mySupplierName"
                                v-if="goodsItem.isMySupplier !== 0"
                                >供</span
                              ><span>{{ goodsItem.supplierUserName }}</span>
                            </p>
                            <p v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode">
                              <span>成本价</span> :
                              <span>￥{{ goodsItem.quotePrice }}</span>
                            </p>
                            <p v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode">
                              <span>售价</span> :
                              <span class="redColor"
                                >￥{{ goodsItem.sellingPrice }}</span
                              >
                            </p>
                             <div style="display:flex;align-Items:center;">
                              <p v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode">
                                <span>利润</span> :
                                <span>￥{{ goodsItem.profit }}</span>
                              </p>
                              <p v-if="goodsItem.goodsName !== '我的报价'&&goodsItem.skuCode"  style="margin-left:3px;">
                                <span
                                  class="expirationDate"
                                  v-if="goodsItem.quoteTime !== '长期有效'"
                                >
                                  {{ goodsItem.quoteTime }}前有效
                                </span>
                                <span
                                  class="expirationDate"
                                  v-if="goodsItem.quoteTime == '长期有效'"
                                >
                                 {{ goodsItem.quoteTime }}
                                </span>

                              </p>
                              </div>
                            <p v-if="goodsItem.goodsName == '我的报价'&&(!goodsItem.skuCode)">
                              <span>报价</span>:
                              <span class="redColor"
                                >￥{{ goodsItem.quotePrice }}</span
                              >
                            </p>
                            <p v-if="goodsItem.goodsName !== '我的报价'&&(!goodsItem.skuCode)">
                              <span>报价</span>:
                              <span class="redColor"
                                >￥{{ goodsItem.quotePrice }}</span
                              >
                            </p>
                            <span class="QuotationTime"  v-if="goodsItem.pushTime">

                                {{goodsItem.pushTime}} 报价
                              </span>
                              <p v-if="goodsItem.isChoose == 1">
                                <span>选择原因</span>:
                                <span  class="viewRemark"  @click="viewRemark(goodsItem)"
                                  >查看</span
                                >
                              </p>
                              <div
                            class="selectGoods"
                            @click="
                              changeGoods(
                                goodsItem.goodsId,
                                goodsItem.memberNeedCode,
                                item,goodsItem
                              )
                            "
                            v-if="goodsItem.isChoose == 0 && activeName!=3"
                          >
                            选择
                          </div>
                          <div
                            class="selectGoods2"
                            v-if="goodsItem.isChoose == 1 && activeName!=3"
                          >
                            已选择
                          </div>
                          </div>

                        </div>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
          </div>
            <el-empty
              description="暂无数据"
              v-if="closeQuoteCount == 0"
            />
            <el-divider border-style="dotted" />
            <div class="generateOrder">
              <div class="generateOrder_left">
                <span>
                  <span
                    ><span class="fontColor">采购计划总数:</span>
                    <span>{{ notQuoteCount + alreadyQuoteCount + closeQuoteCount }}</span
                    >件</span
                  >
                </span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div   style="display: flex; position: absolute; top: 0%; left: 45%;margin-top:10px"  v-if="(activeName==0||activeName==1)&&route.query.type==0">
            <div   class="checkDiv">
            <img :src='!isLook?CheckrdImg:ellipse' :v-model="isLook" alt="" @click="SwitchView(isLook,1)">
            <p class="checkFontStyle">按商品视图</p>
            </div>
            <div  class="checkDiv" >
            <img :src='isLook?CheckrdImg:ellipse' :v-model="!isLook" alt="" @click="SwitchView(isLook,2)">
             <p class="checkFontStyle">按供应商视图</p>

            </div>
            </div>
      </div>
    </div>
    <!-- 查看备注中图片弹窗 -->
    <el-dialog v-model="descImgVisible" title="查看图片" width="820px">
      <div class="desc-image__preview">
        <el-image
          v-for="(url, index) in srcList"
          :key="index"
          :src="url"
          :zoom-rate="1.2"
          :max-scale="7"
          :min-scale="0.2"
          :preview-src-list="srcList"
          :initial-index="4"
          fit="cover"
          :class="{active: index != 0}"
        />
      </div>
  </el-dialog>
    <div class="remarkModal">
    <el-dialog
      v-model="remarkVisible"
      title="选择原因"
      :width="400"
      :before-close="handleClose"
    >
      <div v-if="remarkType==1" class="writeRemark">
        <p><span class="fontColor">选择商品：</span><span class="FontWeight">{{remarkParam.goodsName}}</span></p>
        <p>
          <span class="fontColor">选择原因：</span>
          <el-input
            v-model="remarkParam.chooseCause"
            maxlength="50"
            style="width: 280px;margin-top:8px;"
            placeholder="请输入选择原因"
            show-word-limit
            type="textarea"
          />
        </p>
      </div>
      <div v-if="remarkType==2" class="writeRemark">
        <p><span class="fontColor">选择商品：</span><span class="FontWeight">{{remarkParam.goodsName}}</span></p>
        <p><span class="fontColor"> 供应商：</span><span class="FontWeight">{{remarkParam.supplierName}}</span></p>
        <p><span class="fontColor ">选择原因：</span><span class="Remark">{{remarkParam.chooseCause?remarkParam.chooseCause:"无"}}</span></p>
      </div>
      <template #footer v-if="remarkType==1">
         <div
              style="display: flex; justify-content: flex-end;"
              class="result_btn"
            >
              <div
                class="Clear"

                style="width: 63px; height: 33px; line-height: 33px"
                 @click="handleClose"
              >
                取消
              </div>
              <div
                class="Submit2"
                @click="submitRemark()"
                style="width: 63px; height: 33px; line-height: 33px"
              >
                确定
              </div>
            </div>
      </template>
    </el-dialog>
    </div>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import SldAddressOperation from "../../../components/PlanAddressOperation.vue";
import {
  ElInput,
  ElMessage,
  ElMessageBox,
  ElTimeline,
  ElTimelineItem,
} from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import CheckrdImg from "../../../assets/iconImages/Checked@2x.png";
import ellipse from "../../../assets/iconImages/ellipse@2x.png";
import { platform } from "@/utils/config";
import download from "downloadjs";
export default {
  name: "order-list",
  components: {
    ElInput,
    ElTimeline,
    ElTimelineItem,
    SldCommonEmpty,
    MemberTitle,
    SldAddressOperation,
  },
  setup() {
    // const balance = require("../../assets/buy/balance.png");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const memberInfo = reactive({ data: store.state.memberInfo });
    const { proxy } = getCurrentInstance();
    const addressVisibleFlag = ref(false);
    const L = proxy.$getCurLanguage();
    const descImgVisible = ref(false); //备注中图片弹窗
    const srcList = ref([]); //备注中图片url集合
    const not_select = require("../../../assets/buy/not_select.png");
    const selected = require("../../../assets/buy/selected.png");
    const invoice_selected = require("../../../assets/buy/invoice_selected.png");
    const current_state = ref(""); //当前列表订单状态
    const evaluate_state = ref(0); //是否是待评价
    const fits = ["fill", "contain", "cover", "none", "scale-down"];
    const orderSn = ref(""); //订单id
    const multipleTableRef = ref();
    const dialogVisible = ref(false);
    const AllMoney = ref(0);
    const show_select_address = ref(false);
    const address_list = reactive({ data: [] });
    const topTable = reactive({ data: [] });
    const orderBelongsVal = ref(false); //是否是三方订单
    const activeIndex = ref(0);
    const selectDate = ref("");
    const activeName = ref("0");
    const count = ref("1");
    const ischeckedAll = ref(false);
    const payData = reactive({ data: {} }); //会员拥有的支付、付款数据
    const notQuoteCount = ref(0);
    const alreadyQuoteCount = ref(0);
    const closeQuoteCount = ref(0);
    const nextPageLoaded = ref(false);
    const isSwitchove = ref(false);
    const remarkType = ref(1); //1是填写选择原因，2是查看原因
    const emptyImg = platform == 1?require("../../../assets/emptygc.png"):require("../../../assets/emptyjc.png");
    const cardShowChoose = ref();//已选择供应商视图

    const waitPageData = reactive({
      pageNum: 1,
      pageSize: 5,
      total: 0,
      totalPage: 0,
    });
    const pageData = reactive({
      pageNum: 1,
      pageSize: 5,
      total: 0,
      totalPage: 0,
    });
    const SupplierPageData = reactive({
      pageNum: 1,
      pageSize: 5,
      total: 0,
      totalPage: 0,
    });
    const isLook = ref(false);
    const remarkVisible = ref(false);
    const validateNumber = (rule, value, callback) => {
      return new Promise((resolve, reject) => {
        if (isNaN(value)) {
          reject(new Error("请输入正确报价"));
        } else {
          resolve();
        }
      });
    };
    const rules = {
      myPrice: [
        { required: true, message: "请输入供应商报价", trigger: "blur" },
        {
          validator: validateNumber,
          message: "请输入正确报价",
        },
      ],
    };

    const paymentList = reactive({
      data: [
        //企业会员
        { label: "预付全款", value: 1, disabled: false },
        { label: "货到付款", value: 2, disabled: false },
        { label: "账期支付", value: 3, disabled: false },
      ],
    });

    const multipleSelection = ref([]);
    const current_address_index = ref(0);
    const type = ref("add");

    const tableData = reactive({
      data: [],
      goodsList: [],
      needCount: {},
    });
    const SupplierViewData = reactive({
      data: [],
      goodsList: [],
      needCount: {},
    });
    const closeData = reactive({
      data: [],
      goodsList: [],
      needCount: {},
    });
    let param = reactive({
      memberId: store.state.memberInfo.memberId,
      memberPlanCode: "",
      source: "",
      prayName: "",
      startDate: "",
      endDate: "",
      resource: "",
      state: "",
      pageNum: 1,
      pageSize: 10,
    });
    let remarkParam = reactive({
      goodsId: "",
      memberNeedCode: "",
      chooseCause: "",
      goodsName: "",
    });
    //tab切换
    const handleClick = (tab, event) => {
      // return console.log(activeName.value);
      //isLook.value = false;
      console.log(activeName.value,89898);
      if (activeName.value == 1) {
        //unselectedData();
        if (!isLook.value) {
          waitPageData.pageNum = 1;
          waitPageData.pageSize = 5;
          selectedData([], activeName.value);
        } else {
          SupplierPageData.pageNum = 1;
          getSupplierViewData([]);
        }
      } else if (activeName.value == 3) {
        waitPageData.pageNum = 1;
        waitPageData.pageSize = 5;
        (waitPageData.total = 0),
          (waitPageData.totalPage = 0),
          selectedData([], activeName.value);
      } else if (activeName.value == 0) {
        if (!isLook.value) {
          pageData.pageNum = 1;
          pageData.pageSize = 5;
          unselectedData([]);
        } else {
          SupplierPageData.pageNum = 1;
          getSupplierViewData([]);
        }
      }
    };
    const SwitchView = (value, type) => {
      console.log(value, 7878);
      if (value == false && type == 1) {
        return;
      } else if (value == true && type == 2) {
        return;
      } else {
        //if (value == false) {
        //  getSupplierViewData(SupplierViewData.goodsList);
        //}
        isLook.value = !value;
        console.log(isLook.value);
        if (value) {
          if (activeName.value == 0) {
            unselectedData([]);
          } else {
            selectedData([], activeName.value);
          }
        } else {
          getSupplierViewData([]);
        }
      }
    };
    //去商品详情
    const goGoodsDetail = (name, code) => {
      console.log(name, code);
      if (name != "我的报价") {
        router.push({
          name: "GoodsDetail",
          query: {
            productId: code,
          },
        });
      }
    };

    //返回
    const goback = () => {
      //router.go(-1);
      //pageNum:route.query.pageNum,
      //      pageSize:route.query.pageSize
      router.push({
        path: "/member/procurement/procuremmentPlanDetail",
        query: {
          memberPlanCode: route.query.memberPlanCode,
          pageNum: route.query.pageNum,
          pageSize: route.query.pageSize,
          isLook: isLook.value == true ? 1 : 0,
          type: route.query.type,
        },
      });
    };

    //切换地址
    const selectAddress = (index) => {
      current_address_index.value = index;
    };
    //确认修改地址
    const confirmChangeAddress = () => {
      let param = {
        memberPlanCode: topTable.data.memberPlanCode,
        receivingName:
          address_list.data[current_address_index.value].memberName,
        receivingMobile:
          address_list.data[current_address_index.value].telMobile,
        receivingAddress: `${
          address_list.data[current_address_index.value].addressAll
        },${address_list.data[current_address_index.value].detailAddress}`,
        receivingAddressCode: `${
          address_list.data[current_address_index.value].provinceCode
        },${address_list.data[current_address_index.value].cityCode},${
          address_list.data[current_address_index.value].districtCode
        },`,
      };
      proxy
        .$post("api/shopp/purchase/plan/updatePlan", param, "json")
        .then((res) => {
          if (res.code == 200) {
            show_select_address.value = false;
            getDetailData();
          } else {
            ElMessage(res.msg);
          }
        });
    };

    //获取未选择数据
    const unselectedData = (itemObj) => {
      proxy
        .$get("api/shopp/purchase/plan/needDetailsPage", {
          memberPlanCode: route.query.memberPlanCode,
          //memberNeedCode: route.query.memberNeedCode,
          status: 0,
          ...pageData,
        })
        .then((res) => {
          if (res.code == 200) {
            tableData.data = res.data.records
            pageData.pageNum = res.data.pageNum!==0?res.data.pageNum:1;
            pageData.pageSize = res.data.pageSize!==0?res.data.pageSize:5;
            pageData.total = res.data.total;
            pageData.totalPage = res.data.totalPage;
            console.log(res.data.total);
            tableData.data.forEach((item) => {
              item.goodsList = {
                data: [],
                pageNum: 1,
                pageSize: 5,
                total: 1,
                status: 0,
                totalPage: 1,
              };
              proxy
                .$get("api/shopp/purchase/plan/needDetailsGoodsPage", {
                  memberNeedCode: item.memberNeedCode,
                  pageNum: item.goodsList.pageNum,
                  pageSize: item.goodsList.pageSize,
                  status: 0,
                })
                .then((ress) => {
                  if (ress.code == 200) {
                    if (ress.data.records) {
                      item.goodsList.data = ress.data.records;
                      item.goodsList.total = ress.data.total;
                      item.goodsList.pageSize = ress.data.pageSize;
                      item.goodsList.pageNum = ress.data.pageNum!==0?ress.data.pageNum:1;;
                      item.goodsList.totalPage = ress.data.totalPage;
                      //console.log(item.goodsList.data,33333);
                      //if (ress.data.total <= 6) {
                      //  item.goodsList.total = Number(1);
                      //} else {
                      //  if (Number.isInteger(ress.data.total / 6)) {
                      //    item.goodsList.total = Number(ress.data.total / 6);
                      //  } else {
                      //    item.goodsList.total = Number(
                      //      Math.ceil(ress.data.total / 6)
                      //    );
                      //  }
                      //}
                    } else {
                      item.goodsList.data = [];
                    }
                  } else {
                    ElMessage(ress.msg);
                  }
                })
                .catch(() => {
                  ElMessage(res.msg);
                });
            });
            //for (let i = 0; i < res.data.length; i++) {
            //  res.data[i].goodsList = [];
            //  let len = res.data[i].purchaseQuoteDetailsReqList.length;
            //  const n = 6;
            //  const num = len % 6 === 0 ? len / 6 : Math.floor(len / 6 + 1);
            //  for (let j = 0; j < num; j++) {
            //    const arr = res.data[i].purchaseQuoteDetailsReqList.slice(
            //      j * n,
            //      j * n + n
            //    );

            //    res.data[i].goodsList.push(arr);
            //  }
            //}
            tableData.data = [...itemObj, ...tableData.data];
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    //获取已选择数据
    const selectedData = (itemObj, status) => {
      proxy
        .$get("api/shopp/purchase/plan/needDetailsPage", {
          memberPlanCode: route.query.memberPlanCode,
          status,
          ...waitPageData,
        })
        .then((res) => {
          if (res.code == 200) {
            if (activeName.value == 1) {
              tableData.goodsList = res.data.records;
              waitPageData.pageNum = res.data.pageNum;
              waitPageData.pageSize = res.data.pageSize;
              waitPageData.total = res.data.total;
              waitPageData.totalPage = res.data.totalPage;
              //tableData.data = res.data;
              tableData.goodsList.forEach((item) => {
                item.goodsList = {
                  data: [],
                  pageNum: 1,
                  pageSize: 5,
                  total: 1,
                  totalPage: 1,
                  status,
                };
                proxy
                  .$get("api/shopp/purchase/plan/needDetailsGoodsPage", {
                    memberNeedCode: item.memberNeedCode,
                    pageNum: item.goodsList.pageNum,
                    pageSize: item.goodsList.pageSize,
                    status,
                  })
                  .then((ress) => {
                    if (ress.code == 200) {
                      if (ress.data.records) {
                        item.goodsList.data = ress.data.records;
                        item.goodsList.total = ress.data.total;
                        item.goodsList.pageSize = ress.data.pageSize;
                        item.goodsList.pageNum = ress.data.pageNum;
                        item.goodsList.totalPage = ress.data.totalPage;
                        //if (ress.data.total <= 6) {
                        //  item.goodsList.total = Number(1);
                        //} else {
                        //  if (Number.isInteger(ress.data.total / 6)) {
                        //    item.goodsList.total = Number(ress.data.total / 6);
                        //  } else {
                        //    item.goodsList.total = Number(
                        //      Math.ceil(ress.data.total / 6)
                        //    );
                        //  }
                        //}
                        // changeheight();
                      } else {
                        item.goodsList.data = [];
                      }
                    } else {
                      ElMessage(ress.msg);
                    }
                  })
                  .catch(() => {
                    ElMessage(res.msg);
                  });
                //for (let i = 0; i < res.data.length; i++) {
                //  res.data[i].goodsList = [];
                //  let len = res.data[i].purchaseQuoteDetailsReqList.length;
                //  const n = 6;
                //  const num = len % 6 === 0 ? len / 6 : Math.floor(len / 6 + 1);
                //  for (let j = 0; j < num; j++) {
                //    const arr = res.data[i].purchaseQuoteDetailsReqList.slice(
                //      j * n,
                //      j * n + n
                //    );

                //    res.data[i].goodsList.push(arr);
                //  }
                //}
              });
              tableData.goodsList = [...itemObj, ...tableData.goodsList];
            } else {
              closeData.goodsList = res.data.records;
              waitPageData.pageNum = res.data.pageNum;
              waitPageData.pageSize = res.data.pageSize;
              waitPageData.total = res.data.total;
              waitPageData.totalPage = res.data.totalPage;
              //tableData.data = res.data;
              closeData.goodsList.forEach((item) => {
                item.goodsList = {
                  data: [],
                  pageNum: 1,
                  pageSize: 5,
                  total: 1,
                  totalPage: 1,
                  status,
                };
                proxy
                  .$get("api/shopp/purchase/plan/needDetailsGoodsPage", {
                    memberNeedCode: item.memberNeedCode,
                    pageNum: item.goodsList.pageNum,
                    pageSize: item.goodsList.pageSize,
                    status,
                  })
                  .then((ress) => {
                    if (ress.code == 200) {
                      if (ress.data.records) {
                        item.goodsList.data = ress.data.records;
                        item.goodsList.total = ress.data.total;
                        item.goodsList.pageSize = ress.data.pageSize;
                        item.goodsList.pageNum = ress.data.pageNum;
                        item.goodsList.totalPage = ress.data.totalPage;
                        //if (ress.data.total <= 6) {
                        //  item.goodsList.total = Number(1);
                        //} else {
                        //  if (Number.isInteger(ress.data.total / 6)) {
                        //    item.goodsList.total = Number(ress.data.total / 6);
                        //  } else {
                        //    item.goodsList.total = Number(
                        //      Math.ceil(ress.data.total / 6)
                        //    );
                        //  }
                        //}
                      } else {
                        item.goodsList.data = [];
                      }
                    } else {
                      ElMessage(ress.msg);
                    }
                  })
                  .catch(() => {
                    ElMessage(res.msg);
                  });
                //for (let i = 0; i < res.data.length; i++) {
                //  res.data[i].goodsList = [];
                //  let len = res.data[i].purchaseQuoteDetailsReqList.length;
                //  const n = 6;
                //  const num = len % 6 === 0 ? len / 6 : Math.floor(len / 6 + 1);
                //  for (let j = 0; j < num; j++) {
                //    const arr = res.data[i].purchaseQuoteDetailsReqList.slice(
                //      j * n,
                //      j * n + n
                //    );

                //    res.data[i].goodsList.push(arr);
                //  }
                //}
              });
              closeData.goodsList = [...itemObj, ...closeData.goodsList];
            }
          }
        })
        .catch(() => {
          //异常处理
        });
    };

    const getData = () => {
      unselectedData([]);
    };

    const changeGoods = (goodsId, memberNeedCode, item, goodsItem) => {
      remarkVisible.value = true;
      remarkType.value = 1;
      console.log(goodsId);
      remarkParam.goodsId = goodsId;
      remarkParam.memberNeedCode = memberNeedCode;
      remarkParam.goodsName=goodsItem.goodsName
      //ElMessageBox.prompt("请填写选择原因", "选择原因", {
      //  confirmButtonText: "确认",
      //  cancelButtonText: "取消",
      // customClass: "reasonModal",
      //  inputValidator: (value) => {
      //    console.log(value);
      //    if (!value) {
      //      return '请填写选择原因';
      //    }else if(!value?.trim()){
      //      return '请填写选择原因';
      //    }else if(value?.split("").length>=300){
      //      return '内容不能超过300字';
      //    }
      //    return true;
      //  },
      //})
      //  .then(({ value }) => {

      //
    };
    const otherGoodsList = (pageNum, memberNeedCode, item, status) => {
      proxy
        .$get("api/shopp/purchase/plan/needDetailsGoodsPage", {
          memberNeedCode: memberNeedCode,
          pageNum: pageNum,
          pageSize: 5,
          status,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.records) {
              item.goodsList.data = res.data.records;
              item.goodsList.pageNum = res.data.pageNum;
              if (res.data.total <= 5) {
                item.goodsList.total = 1;
              } else {
                //if (Number.isInteger(res.data.total / 6)) {
                //  item.goodsList.total = res.data.total / 6;
                //} else {
                //  item.goodsList.total = Math.floor(res.data.total / 6);
                //}
              }
            } else {
              item.goodsList.data = [];
            }
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {});
    };
    const changeGoosList = (pageNum, memberNeedCode, item) => {
      if (
        item.goodsList.totalPage != 1 &&
        pageNum !== item.goodsList.totalPage
      ) {
        otherGoodsList(
          pageNum + 1,
          memberNeedCode,
          item,
          item.goodsList.status
        );
      } else if (item.goodsList.total <= 5) {
        return;
      } else if (
        item.goodsList.totalPage != 1 &&
        pageNum == item.goodsList.totalPage
      ) {
        otherGoodsList(1, memberNeedCode, item, item.goodsList.status);
      } else {
        otherGoodsList(
          pageNum - 1,
          memberNeedCode,
          item,
          item.goodsList.status
        );
      }
      //if(item.goodsList.total = 1){
      //  this.refs.carousel.nextprev
      //  this.refs.carousel.go(1)
      //  otherGoodsList(1, needCode, item);

      //}
    };
    const setActiveItem = (prev, next) => {
      console.log(prev, next);
    };
    const getTabsCount = () => {
      proxy
        .$get("api/shopp/purchase/plan/needDetailsPageCount", {
          memberPlanCode: route.query.memberPlanCode,
        })
        .then((res) => {
          if (res.code == 200) {
            notQuoteCount.value = res.data.notQuote;
            alreadyQuoteCount.value = res.data.alreadyQuote;
            closeQuoteCount.value = res.data.closeQuote
              ? res.data.closeQuote
              : 0;
          } else {
            ElMessage(res.msg);
          }
        })
        .catch((res) => {
          //ElMessage(res.msg);
        });
    };
    const debounce = (fn, delay) => {
      let timeout;
      return function () {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fn.apply(this, arguments);
        }, delay);
      };
    };
    const handleScroll = (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      console.log(scrollTop + clientHeight == scrollHeight);
      console.log(pageData);
      if (activeName.value == 0) {
        if (
          scrollTop + clientHeight >= scrollHeight - 1 &&
          pageData.totalPage !== pageData.pageNum
        ) {
          // 当距离底部5px以内滚动时加载
          pageData.pageNum = Number(pageData.pageNum) + 1;
          let data = tableData.data;
          debounce(unselectedData(data), 1000);
        }
      } else {
        if (
          scrollTop + clientHeight >= scrollHeight - 1 &&
          waitPageData.totalPage !== waitPageData.pageNum
        ) {
          // 当距离底部5px以内滚动时加载
          waitPageData.pageNum = Number(waitPageData.pageNum) + 1;
          let data = [];
          activeName.value == 1
            ? (data = [...tableData.goodsList])
            : (data = [...closeData.goodsList]);
          debounce(selectedData(data, activeName.value), 1000);
        }
      }
    };

    //备注中查看图片
    const previewImg = (imgs) => {
      srcList.value = imgs.split(",");
      descImgVisible.value = true;
    };
    const getSupplierViewData = (data) => {
      proxy
        .$get("api/shopp/purchase/plan/needDetailsSupplierShowPage", {
          memberPlanCode: route.query.memberPlanCode,
          ...SupplierPageData,
          state: activeName.value,
          //state: 0,
        })
        .then((res) => {
          if (res.code == 200) {
            nextPageLoaded.value = false;
            console.log(12121);
            SupplierPageData.pageNum = res.data.pageNum;
            SupplierPageData.pageSize = res.data.pageSize;
            SupplierPageData.total = res.data.total;
            SupplierPageData.totalPage = res.data.totalPage;
            console.log(data, 6756);

            //if(activeName.value==0){
            //  notQuoteCount.value=res.data.total
            //}else if(activeName.value===1){
            //  alreadyQuoteCount.value=res.data.total
            //}
            // if(type){
            //  SupplierViewData.data=[...SupplierViewData.data,...res.data.records]
            // }else{

            SupplierViewData.goodsList = res.data.records;
            // }
            console.log(SupplierViewData.data);

            //payData.data = res.data;
            SupplierViewData.goodsList.forEach((item) => {
              console.log(item);
              item.goodsList = {
                data: [],
                data1: [],
                pageNum: 1,
                pageSize: 5,
                total: 1,
                totalPage: 1,
              };
              item.quoteAlMoney = 0;
              item.num = [];
              proxy
                .$get(
                  "api/shopp/purchase/plan/needDetailsSupplierShowGoodsPage",
                  {
                    memberPlanCode: route.query.memberPlanCode,
                    supplierId: item.supplierUserId,
                    chooseGoods: "1",
                    state: activeName.value,
                    //pageNum: item.goodsList.pageNum,
                    //pageSize: item.goodsList.pageSize,
                  }
                )
                .then((res) => {
                  if (res.code == 200) {
                    if (res.data) {
                      item.goodsList.data1 = res.data;
                      item.goodsList.data = res.data;
                      console.log(item.goodsList.data1, 34353453);
                      let total = 0;
                      item.goodsList.data1.forEach((its) => {
                        console.log(item, 454546);
                        item.num.push(its.needCode);
                        total +=
                          Math.ceil(
                            (Number(its.sellingPrice) *
                              100 *
                              (Number(its.purchaseNum) * 10000)) /
                              1000
                          ) / 1000;
                      });
                      item.quoteAlMoney = total.toFixed(2);
                      item.num = [...new Set(item.num)];
                      if (
                        item.goodsList.data.every((it) => it.isChooseGoods == 1)
                      ) {
                        item.isCancel = true;
                      }

                      console.log(item.ischeckedAll);

                      console.log(item.goodsList.data, 8989);

                      item.goodsList.pageNum = 1;
                      item.goodsList.pageSize = 5;
                      item.goodsList.data = item.goodsList.data1;

                      item.goodsList.totalPage =
                        res.data.length % 5 === 0
                          ? res.data.length / 5
                          : Math.floor(res.data.length / 5 + 1);
                      //item.goodsList.data = item.goodsList.data1.slice(0, 5);
                      let goodsList = [];
                      for (let i = 0; i < item.goodsList.totalPage; i++) {
                        const arr = item.goodsList.data.slice(i * 5, i * 5 + 5);
                        goodsList.push(arr);
                      }
                      console.log(goodsList, 4354);
                      item.goodsList.data = goodsList;
                      item.goodsList.total = item.goodsList.data.length;

                        let check = ()=>{
                                let he = document.getElementById('chooseitem').offsetHeight
                                if(he){
                                    clearInterval(setInttime)
                                    cardShowChoose.value = he+15+'px'
                                }
                            }
                            var setInttime = setInterval(check,50)

                    } else {
                      item.goodsList.data = [];
                    }
                  } else {
                    ElMessage(res.msg);
                  }
                })
                .catch(() => {
                  //ElMessage(res.msg);
                });
            });
            //tableData.goodsList = [...tableData.goodsList];
            SupplierViewData.goodsList = [
              ...data,
              ...SupplierViewData.goodsList,
            ];
          }

          //console.log(SupplierViewData.goodsList, 444444);
        });
    };
    const handleScroll2 = (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      console.log(scrollTop, scrollHeight, clientHeight);
      if (
        scrollHeight - (scrollTop + clientHeight) < 50 &&
        SupplierPageData.totalPage !== SupplierPageData.pageNum
      ) {
        if (!nextPageLoaded.value) {
          console.log(4545);

          // 将标记设置为已加载
          nextPageLoaded.value = true;
          // 当距离底部5px以内滚动时加载
          //multipleSelection.value=[]
          SupplierPageData.pageNum = Number(SupplierPageData.pageNum) + 1;

          getSupplierViewData(SupplierViewData.goodsList);
        }
      }
    };
    //供应商视图滚动条分页
    const handleCarouselChange = (pageNum, needCode, item) => {
      if (
        item.goodsList.totalPage != 1 &&
        pageNum !== item.goodsList.totalPage
      ) {
        //console.log(111);
        otherGoodsList2(pageNum + 1, needCode, item);
      } else if (item.goodsList.total <= 5) {
        //console.log(222);
        return;
      } else if (
        item.goodsList.totalPage != 1 &&
        pageNum == item.goodsList.totalPage
      ) {
        //console.log(333);
        otherGoodsList2(1, needCode, item);
      } else {
        otherGoodsList2(pageNum - 1, needCode, item);
      }
    };
    const otherGoodsList2 = (pageNum, needCode, item) => {
      if (item.goodsList.data1) {
        item.goodsList.data = item.goodsList.data1;
        item.goodsList.pageNum = pageNum;
        item.goodsList.pageSize = 5;

        item.goodsList.totalPage =
          item.goodsList.data1.length % 5 === 0
            ? item.goodsList.data1.length / 5
            : Math.floor(item.goodsList.data1.length / 5 + 1);
        console.log(pageNum);
        if (pageNum != 1) {
          item.goodsList.data = item.goodsList.data.slice(
            (pageNum - 1) * 5,
            pageNum * 5
          );
          console.log((pageNum - 1) * 5, pageNum * 5, item.goodsList.totalPage);
        } else {
          item.goodsList.data = item.goodsList.data.slice(0, 5);
        }
        item.goodsList.total = item.goodsList.data1.length;
        //if (res.data.total <= 5) {
        //  item.goodsList.total = 1;
        //} else {
        //  //if (Number.isInteger(res.data.total / 6)) {
        //  //  item.goodsList.total = res.data.total / 6;
        //  //} else {
        //  //  item.goodsList.total = Math.floor(res.data.total / 6);
        //  //}
        //}
      } else {
        item.goodsList.data = [];
      }
    };
    const changeGoods2 = (quoteCode, memberNeedCode, item,goodsItem) => {
      remarkVisible.value = true;
      remarkType.value = 1;
      remarkParam.needCode = memberNeedCode;
      remarkParam.goodsName=goodsItem.goodsNameJoin;
      remarkParam.quoteCode=goodsItem.goodsId
    };
    const supplierChangeGoods=()=>{
      proxy
            .$post(
              "api/shopp/purchase/plan/chooseGoodsSupplierLook",
              [{needCode:remarkParam.needCode,
              quoteCode:remarkParam.quoteCode,
              chooseCause:remarkParam.chooseCause
              }],

              "json"
            )
            .then((res) => {
              if (res.code == 200) {
                remarkParam.chooseCause=""
                remarkVisible.value=false
                if (activeName.value == 0) {
                  SupplierPageData.pageNum = 1;
                  getTabsCount();
                }
                SupplierPageData.pageNum = 1;
                getSupplierViewData();

                ElMessage({
                  message: res.msg,
                  type: "success",
                });
              } else {
                ElMessage(res.msg);
              }
            })
            .catch(() => {});
    }
    const cancelGoods = (goodsId, memberNeedCode, item) => {
      let arr = [
        {
          quoteCode: goodsId,
          needCode: memberNeedCode,
        },
      ];
      proxy
        .$post("api/shopp/purchase/plan/cancelGoodsSupplierLook", arr, "json")
        .then((res) => {
          if (res.code == 200) {
            //if (activeName.value == 0) {
            //SupplierPageData.pageNum = 1;
            //unselectedData([]);
            getTabsCount();
            //}else{

            //}
            //waitPageData.pageNum=1
            console.log(item);

            SupplierPageData.pageNum = 1;
            getSupplierViewData([]);
            prevNeedCode.value = needCode;
            for (let i = 0; i < SupplierViewData.goodsList.length; i++) {
              for (
                let j = 0;
                j < SupplierViewData.goodsList[i].goodsList.data1.length;
                j++
              ) {
                if (
                  SupplierViewData.goodsList[i].goodsList.data1[j]?.needCode ==
                  needCode
                ) {
                  SupplierViewData.goodsList[i].goodsList.data1[
                    j
                  ].select = true;
                  SupplierViewData.goodsList[i].goodsList.data =
                    SupplierViewData.goodsList[i].goodsList.data1.slice(
                      (SupplierViewData.goodsList[i].goodsList.pageNum - 1) * 5,
                      SupplierViewData.goodsList[i].goodsList.pageNum * 5
                    );
                }
              }
            }

            //getData();

            ElMessage({
              message: res.msg,
              type: "success",
            });
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {});
    };
    //选择全部
    const selectAll = (item) => {
      let arr = [];
      item.goodsList.data1.forEach((it) => {
        console.log(it);
        if (it.isChooseGoods != 1) {
          arr.push({ quoteCode: it.goodsId, needCode: it.memberNeedCode });
        }
      });

      proxy
        .$post("api/shopp/purchase/plan/chooseGoodsSupplierLook", arr, "json")
        .then((res) => {
          if (res.code == 200) {
            if (activeName.value == 0) {
              SupplierPageData.pageNum = 1;
              //unselectedData([]);
              getTabsCount();
            }
            //waitPageData.pageNum=1
            console.log(item);

            SupplierPageData.pageNum = 1;
            getSupplierViewData([]);

            //getData();

            ElMessage({
              message: res.msg,
              type: "success",
            });
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {});
    };
    //取消全部
    const CancelAll = (item) => {
      let arr = [];
      item.goodsList.data1.forEach((it) => {
        console.log(it);
        if (it.isChooseGoods == 1) {
          arr.push({ quoteCode: it.goodsId, needCode: it.memberNeedCode });
        }
      });

      proxy
        .$post("api/shopp/purchase/plan/cancelGoodsSupplierLook", arr, "json")
        .then((res) => {
          if (res.code == 200) {
            //if (activeName.value == 0) {
            SupplierPageData.pageNum = 1;
            //unselectedData([]);
            getTabsCount();
            //}
            //waitPageData.pageNum=1
            console.log(item);

            SupplierPageData.pageNum = 1;
            getSupplierViewData([]);

            //getData();

            ElMessage({
              message: res.msg,
              type: "success",
            });
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {});
    };

    const submitRemark = () => {
       console.log(activeName.value,89898);
    //if(remarkParam.chooseCause){
            if (!isLook.value) {
              goodsChangeGoods()
              }else{
                supplierChangeGoods()
              }


    //}else{
    //  ElMessage.warning("请填写选择原因");
    //}
    };
    const viewRemark = (goodsItem) => {
      proxy
        .$get("api/shopp/purchase/plan/queryChooseCause", {
          memberNeedCode: goodsItem.memberNeedCode,
        })
        .then((res) => {
          if (res.code == 200) {
            remarkParam.goodsName = res.data.goodsName;
            remarkParam.chooseCause = res.data.chooseCause;
            remarkParam.supplierName = res.data.supplierName;
            remarkType.value = 2;
            remarkVisible.value = true;
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {});

    };
    const goodsChangeGoods=()=>{
        proxy
            .$get("api/shopp/purchase/plan/chooseGoods", remarkParam)
              .then((res) => {
                if (res.code == 200) {
                 remarkParam.chooseCause=""
                  remarkVisible.value=false;
                  if (activeName.value == 0) {

                    pageData.pageNum = 1;
                    unselectedData([]);
                    getTabsCount();
                  } else {
                    console.log(!isLook.value);
                    if (!isLook.value) {
                      tableData.goodsList.forEach(item=>{
                        if(item.memberNeedCode=remarkParam.memberNeedCode){
                          console.log(item,64746);
                           proxy
                        .$get("api/shopp/purchase/plan/needDetailsGoodsPage", {
                          memberNeedCode:  item.memberNeedCode,
                          pageNum: item.goodsList.pageNum,
                          pageSize: item.goodsList.pageSize,
                          status: 1,
                        })
                        .then((ress) => {
                          if (ress.code == 200) {
                            if (ress.data.records) {
                              item.goodsList.data = ress.data.records;
                              item.goodsList.total = ress.data.total;
                              item.goodsList.pageSize = ress.data.pageSize;
                              item.goodsList.pageNum = ress.data.pageNum;
                              item.goodsList.totalPage = ress.data.totalPage;
                            } else {
                              item.goodsList.data = [];
                            }
                          } else {
                            ElMessage(ress.msg);
                          }
                        })
                        .catch(() => {});
                        }
                      })

                    } else {
                      SupplierPageData.pageNum = 1;
                      getSupplierViewData(SupplierViewData.goodsList);
                    }
                  }
                  //getData();

                    ElMessage({
                    message: res.msg,
                    type: "success",
                  });
                } else {
                  ElMessage(res.msg);
                }
              })
              .catch(() => {});
    }
    const handleClose=()=>{
      remarkVisible.value=false
      remarkParam.chooseCause=""
    }

    onMounted(() => {
      if (route.query.orderState) {
        current_state.value = route.query.orderState;
      } else {
        current_state.value = "";
      }

      if (route.query.evaluateState) {
        evaluate_state.value = route.query.evaluateState;
      } else {
        evaluate_state.value = "";
      }
      isLook.value = route.query.isLook == 1 ? true : false;
      console.log(isLook.value, 3434);
      if (isLook.value) {
        getSupplierViewData([]);
      } else {
        getData();
      }
      getTabsCount();

    });

    return {
      L,
      param,
      tableData,
      closeData,
      fits,
      multipleTableRef,
      multipleSelection,
      //handleSelectionChange,
      AllMoney,
      descImgVisible,
      srcList,
      previewImg,
      goback,
      getTabsCount,
      addressVisibleFlag,
      waitPageData,
      type,
      show_select_address,

      payData,
      orderBelongsVal,
      selectDate,
      activeIndex,
      paymentList,
      not_select,
      selected,
      invoice_selected,
      current_address_index,

      address_list,
      goGoodsDetail,
      //toggleSelection
      dialogVisible,

      validateNumber,
      handleClick,
      rules,
      activeName,
      topTable,
      pageData,
      ischeckedAll,

      multipleSelection,
      changeGoods,
      getData,
      changeGoosList,
      setActiveItem,
      notQuoteCount,
      alreadyQuoteCount,
      closeQuoteCount,
      handleScroll,
      isLook,
      CheckrdImg,
      ellipse,
      SwitchView,
      handleScroll2,
      SupplierViewData,
      handleCarouselChange,
      nextPageLoaded,
      changeGoods2,
      cancelGoods,
      selectAll,
      SupplierPageData,
      CancelAll,
      isSwitchove,
      route,
      emptyImg,
      store,
      remarkVisible,
      remarkType,
      remarkParam,
      submitRemark,
      viewRemark,
      handleClose,supplierChangeGoods,
      goodsChangeGoods,
      cardShowChoose,
    };
  },
};
</script>
<style lang="scss" >
@import "../../../style/quotedPriceDetail.scss";
.caigouxiangqing{
   .tabs {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: #fff;
  background: #fff;
  border: 1px solid #cfcfcfff;
  margin-right: 10px;
  font-size: 14px;
}
}


.el-dialog .el-dialog__body .desc-image__preview {
  .el-image {
    width: 148px;
    height: 148px;
  }
  .active {
    margin-left: 10px;
  }
}
.desc-clss {
  display: inline-block;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏溢出容器的文本 */
  text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
  max-width: 100px; /* 设置最大宽度 */
}
.imgBtn {
  color: $colorMain;
  cursor: pointer;
  margin-left: 4px;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0e3177 !important;
  border-color: #0e3177 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #0e3177 !important;
  border-color: #0e3177 !important;
}
.sld_member_main_content[data-v-61d93b18] {
  background: #f8f8f8;
}
.sld_order_list .container {
  /*width: 1207px;*/
}
.sld_order_list {
  /*width: 1210px;*/
  width: 100%;
  background: #fff;
}
.el-pager li.active {
  color: $colorMain;
  cursor: default;
}

.el-pager li:hover {
  color: $colorMain;
}

.el-select-dropdown__item.selected {
  color: $colorMain;
}

.sld_order_list {
  color: #000;
  .sld_List {
    color: #000;
  }
  /*.el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: auto;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }
  .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }
  .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }*/
  /*.aft_btn:hover {
    background-color: rgba($color: #f30213, $alpha: 0.2);
    color: #f30213;
  }*/
  .aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
  }
  .dia_btn {
    .el-button {
      width: 134px;
      height: 36px;
    }
  }
  .downLoad_contract {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e8e8f1;
    border-radius: 5px;
    cursor: pointer;
  }
  .downLoad_contract:hover {
    border: 1px solid $colorMain;
  }
}
/*.container {
  .bootom-footer {
    text-align: right;
  }
  .bootom-footer button:first-child {
    margin-right: 10px;
  }
  // .el-input {
  //   width: 300px;
  // }
  .unit {
    font-size: 18px;
    margin-left: 10px;
  }
}*/
.demo-form-inline .el-input {
  --el-input-width: 220px;
}
.el-table thead {
  color: #5f5f60;
}
.el-table {
  color: #2b2b2c;
}
.result {
  height: 100%;
  margin-top: 30px;
  text-align: center;
  padding: 0px 20px 50px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  margin-bottom: 40px;
}
.child {
  width: 100%;
  display: flex;
  align-content: center;
  .goodsMassage {
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    width: 92%;
    align-items: center;
    .operationContent {
      width: 100%;
      line-height: 2;
      .goodsContent {
        display: flex;
        p {
          width: 30%;
          line-height: 2.5;
        }
      }
    }
  }
  .goodsMassages {
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    width: 90%;
    /*align-items: ;*/
    .operationContent {
      width: 100%;
      line-height: 2;
      .goodsContent {
        display: flex;
        p {
          width: 30%;
          line-height: 2.5;
        }
      }
    }
  }
}
.showContent {
  margin-top: 20px;
  padding: 10px;
  background: rgb(250, 247, 247);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.receive_info {
  border: 1px solid #dddddd;
  border-radius: 2px;
  margin-bottom: 30px;
  .pre_message_title,
  .receive_info_title {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    padding-left: 20px;
    background-color: #f8f8f8;
    color: #666666;
  }
  .receive_info_content {
    height: 159px;
    padding: 0 40px;
  }

  .pre_message_info {
    padding: 10px 40px;
    .tag_pre {
      display: inline-block;
      line-height: 25px;
      text-align: right;
      font-weight: 400;
      margin-right: 10px;
      font-size: 14px;
      color: #333333;
      display: flex;
      min-width: 100px;
      max-width: 200px;
      justify-content: flex-end;
    }

    strong {
      font-size: 14px;
      color: $colorMain;
      margin-right: 3px;
    }
  }
  .content_left {
    width: 785px;
    /*border-right: 1px solid #dddddd;*/
    .member {
      font-size: 14px;
    }
    .address_detail {
      width: 700px;
    }
    span:not(:first-child) {
      font-size: 14px;
      line-height: 14px;
      margin-top: 22px;
    }
  }
  .content_right {
    width: 370px;
    font-size: 14px;
    border-left: 1px solid #dddddd;
    padding-left: 10px;
    .replace_address {
      color: $colorMain2;
      line-height: 14px;
    }
  }
  .add_address {
    width: 100px;
    height: 30px;
    background-color: $colorMain;
    color: white;
    line-height: 30px;
    text-align: center;
    margin-top: 29px;
    border-radius: 3px;
  }
  .nomartop {
    margin-top: 0;
  }
}
.select_address_width {
  width: 500px !important;
}
.overflow_ellipsis_clamp2 {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;

  line-height: 19px;
}
.out_stock_dialog {
  width: 460px;
  margin: 0 auto;
  height: 330px;
  overflow-y: scroll;
  .good_item {
    font-size: 14px;

    img {
      width: 80px;
      height: 80px;
    }
    .good_info {
      margin-left: 10px;
    }
    .good_name {
      width: 320px;
      color: #333333;
      line-height: 14px;
      margin-top: 10px;
      display: inline-block;
    }
    .spec_num {
      margin-top: 26px;
      .good_spec {
        color: #999999;
      }
      .good_num {
        float: right;
        color: #333333;
      }
    }
  }
}
.address_con {
  height: 330px;
  overflow-y: scroll;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  .address_item {
    &:not(:first-child) {
      margin-top: 20px;
    }
    width: 458px;
    //    height: 104px;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    position: relative;
    padding: 20px;
    span,
    div:not(:first-child) {
      margin-top: 12px;
    }
    .address_text {
      display: flex;
      width: 400px;
    }
    .selected {
      width: 14px;
      height: 14px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .select {
    border: 1px solid $colorMain2;
  }
}
.red {
  background-color: $colorMain;
  color: white;
}
.remove {
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
  background-color: $colorMain;
  margin-left: 10px;
  color: white;
}
.replace_address {
  color: $colorMain2;
  line-height: 14px;
}
.Submit {
  background: #0e3177;
  width: 98px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-left: 10px;
  line-height: 40px;
  font-size: 14px;
}

/*.btnActive {
  background: #0e3177;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
}*/
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e2e2;
  padding: 13px 20px 0px 15px;
  background-color: $colorMain;
  color: white;
  padding-bottom: 10px;
}
/*::v-deep .el-dialog__header {
  padding: 0px;
  padding-bottom: 0px;
}*/
.modalTitle {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  img {
    width: 27px;
    height: 24px;
    /*margin-right: 5px;*/
  }
}
// ::v-deep .el-dialog__body {
//   padding-top: 0px;
//   padding-bottom: 0px !important;
//   padding-left: 0px;
//   padding-right: 0px;
//   /*margin:13px 10px 30px 10px;*/
// }
.Clear {
  width: 76px;
  height: 35px;
  border-radius: 4px;
  text-align: center;
  color: #5e5858;
  line-height: 35px;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.Submit2 {
  background: #0e3177;
  width: 76px;
  height: 35px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-left: 10px;
  line-height: 35px;
  font-size: 14px;
  cursor: pointer;
}
/*.invoice_cons {
  height: 100px;
  display: flex;
  justify-content: center;
  .activeLt {
    margin-left: 20px;
  }
  .no_select_invoice {
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
    }
    span {
      font-size: 14px;
      line-height: 14px;
      margin-left: 10px;
    }
    .invoice_info {
      margin-left: 50px;
      border-left: 1px solid #dddddd;
      span {
        font-size: 14px;
        line-height: 14px;
        margin-left: 50px;
      }
      .choose {
        color: #257bfd;
        cursor: pointer;
      }
    }
    .block {
      margin-left: 10px;
      ::v-deep .el-date-editor.el-input {
        width: 160px;
      }
      .el-input__inner {
        padding-left: 40px;
      }
    }
    .date_box {
      margin-left: 10px;
      ::v-deep .el-date-editor.el-input {
        width: 160px;
      }
    }
  }
}*/
/*.el-button {
  color: #fff;
  background-color: #0e3177;
  border-color: #0e3177;
}*/
/*.ExitConfirmButton {
  color: #fff;
  background-color: #0e3177;
  border-color: #0e3177;
}*/
.ExitConfirmButton {
  color: #fff;
  background-color: #0e3177 !important;
  border-color: #0e3177 !important;
}
.OtherRecommendations{
.el-carousel__container{
    min-height: 340px;
height: auto!important;
    // padding-bottom: 500px;
    // overflow: hidden;
//     ::after{
//         content: '';
//   display: block;
//   clear: both;
//   visibility: hidden;
//   height: 0;
//     }
}
.el-carousel__item{
    height: auto;
}
}

</style>